import { ObservabilityErrorFrom } from 'src/constants';
import { ErrorInfo } from 'react';
import { init, captureException, setUser } from '@sentry/react';
import getBootstrapData from '../getBootstrapData';

class SentryObservability {
  private sentryDns: string;

  constructor(sentryDns: string) {
    this.sentryDns = sentryDns;
  }

  public async init() {
    try {
      init({
        dsn: this.sentryDns,
        ignoreErrors: [
          /Java object is gone/,
          /Java bridge method invocation error/,
        ],
      });
    } catch {
      console.error('Failed to initialize Sentry');
    }
  }

  public async setUser(user: Record<string, string>) {
    try {
      setUser(user);
    } catch {
      console.error('Failed to set user in Sentry');
    }
  }

  public async captureExeption({
    error,
    error_from,
  }: {
    error_from: ObservabilityErrorFrom;
    error: Error;
  }) {
    try {
      captureException(error, {
        tags: {
          ErrorCategory: error_from || ObservabilityErrorFrom.CAPTURE_EXCEPTION,
        },
      });
    } catch {
      console.error('Failed to capture exception');
    }
  }

  public async captureErrorBoundary(error: Error, errorInfo: ErrorInfo) {
    try {
      captureException(error, {
        extra: { componentStack: errorInfo.componentStack },
        tags: {
          ErrorCategory: ObservabilityErrorFrom.REACT_ERROR_BOUNDARY,
        },
      });
    } catch {
      console.error('Failed to capture error boundary');
    }
  }
}

export default ((): SentryObservability | undefined => {
  const bootstrapData = getBootstrapData();
  const isProduction = process.env.NODE_ENV === 'production';
  const SENTRY_DNS = bootstrapData?.common?.conf?.SENTRY_DNS;
  if (SENTRY_DNS && isProduction) {
    return new SentryObservability(SENTRY_DNS);
  }
  return undefined;
})();
